<template>
		<Layout>
			<v-container>
				<v-row>
					<v-col cols="12">
						<h2> <v-icon large>mdi-calendar</v-icon> EDITAR EVENTO</h2>
					</v-col>
				</v-row>
				<v-form ref="form" v-model="formValidity">

					<v-row class="justify-center">

						<v-col cols="12" sm="8">
							<v-text-field
								label="Título"
								v-model="evento.titulo"
								:rules="[rules.required]"
							/>

							<v-row>
								<v-col cols="12" sm="6">
									<v-text-field
										type="date"
										label="Fecha Inicio"
										v-model="evento.fechaInicio"
									/>
								</v-col>
								<v-col cols="12" sm="6">
									<v-text-field
										type="date"
										label="Fecha Final"
										v-model="evento.fechaFin"
									/>
								</v-col>
							</v-row>

							<v-text-field
								label="Slug"
								v-model="evento.slug"
								:rules="[rules.required]"
							/>

							<v-text-field
								label="Subtítulo (Fecha y sitio)"
								v-model="evento.subtitulo"
								:rules="[rules.required]"
							/>
							<!-- <h4>Subtítulo</h4>
								<tiptap-vuetify
								v-model="evento.subtitulo"
								:extensions="extensions"
							/> -->


							<h4>Información (Opcional)</h4>
							<tiptap-vuetify
								v-model="evento.informacion"
								:extensions="extensions"
							/>

							<div class="d-flex flex-row justify-space-between">
								<v-switch
									inset class="ma-2"
									color="success"
									v-model="evento.presencial"
									:label="`¿Solo Presencial?: ${evento.presencial ? 'SI' : 'NO'}`"
								></v-switch>
								<v-switch
									inset class="ma-2"
									color="success"
									v-model="evento.gratis"
									:label="`¿Evento gratis?: ${evento.gratis ? 'SI' : 'NO'}`"
								></v-switch>

							</div>

							<section v-if="!evento.gratis">
								<precios-component :precios="precios" @emitPrecios="setPrecios" />
							</section>



							<v-text-field
								label="URL Afiche"
								v-model="evento.afiche"
								:rules="[rules.required]"
							/>
							<v-text-field
								label="URL Programa"
								v-model="evento.programa"
								:rules="[rules.required]"
							/>
							<v-text-field
								label="URL Librito"
								v-model="evento.librito"
							/>
							<v-text-field
								label="URL zoom Público"
								v-model="evento.webinar"
							/>
							<v-text-field
								label="URL zoom Privado"
								v-model="evento.meeting"
							/>
							<section class="grey lighten-3 pa-3">
								<h4>Banner Detalle Header</h4>
								<v-text-field
									label="Imagen"
									v-model="evento.bannerDetalleHeader.image"
								/>
								<v-text-field
									label="Link"
									v-model="evento.bannerDetalleHeader.link"
								/>
							</section>
							<section class="grey lighten-3 pa-3 my-3">
								<h4>Banner Inscripción Header</h4>
								<v-text-field
									label="Imagen"
									v-model="evento.bannerInscripcionHeader.image"
								/>
								<v-text-field
									label="Link"
									v-model="evento.bannerInscripcionHeader.link"
								/>
							</section>
							<section class="grey lighten-3 pa-3 my-3">
								<h4>Banner Inscripción Email Header</h4>
								<v-text-field
									label="Imagen"
									v-model="evento.bannerInscripcionEmail.image"
								/>
								<v-text-field
									label="Link"
									v-model="evento.bannerInscripcionEmail.link"
								/>
							</section>
							<section class="grey lighten-3 pa-3 my-3">
								<h4>Banner Transmisión Header</h4>
								<v-text-field
									label="Imagen"
									v-model="evento.bannerTransmisionHeader.image"
								/>
								<v-text-field
									label="Link"
									v-model="evento.bannerTransmisionHeader.link"
								/>
							</section>
							<section class="grey lighten-3 pa-3 my-3">
								<h4>Banner Transmisión Footer</h4>
								<v-text-field
									label="Imagen"
									v-model="evento.bannerTransmisionFooter.image"
								/>
								<v-text-field
									label="Link"
									v-model="evento.bannerTransmisionFooter.link"
								/>
							</section>
							<sponsors-component :sponsors="sponsors" @emitSponsors="setSponsors" />

						</v-col>

						<v-col cols="12">
							<div class="text-center my-5">
								<v-btn
									@click="goEventoList()"
									x-large
									text
									color="info accent-3"
								>
									Regresar
								</v-btn>
								<v-btn
									x-large
									@click.stop="updateEvento()"
									:loading="saving"
									:disabled="!formValidity"
									rounded
									color="primary accent-3">
									ACTUALIZAR EVENTO
								</v-btn>
							</div>
						</v-col>
					</v-row>

				</v-form>
			</v-container>
		</Layout>
</template>

<script>
	import Helpers from '@/services/Helpers.js'
	import { mapState } from 'vuex';
	import axios from 'axios';
	import store from '@/store'
	import PreciosComponent from '@/components/eventos/PreciosComponent.vue';
	import SponsorsComponent from '@/components/eventos/SponsorsComponent.vue'
	import { TiptapVuetify, HardBreak, Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HorizontalRule, History } from 'tiptap-vuetify'
	const header = {"Token": store.state.userModule.token}
	const configuration = {headers: header}
	const banner = {
    image: "#",
    link: "#"
  }

	export default {
		name: 'EventoEdit',
		components: {
			TiptapVuetify,
			PreciosComponent,
			SponsorsComponent
		},
		data: () => ({
			loading: false,
			saving: false,
			formValidity: false,
			precios: [],
			sponsors: [],
			rules: {
				required: value => !!value || 'Required.',
			},
			evento: {
				titulo: '',
				slug: '',
				subtitulo: '',
				informacion: '',
				fechaInicio: '',
				fechaFin:'',
				gratis: true,
				presencial: false,
				precios: [],
				afiche: '',
				programa: '',
				librito: '',
				webinar: '',
				meeting: '',
				enVivo: false,
				active: false,
				bannerDetalleHeader: {},
				bannerInscripcionHeader: {},
				bannerInscripcionEmail: {},
				bannerTransmisionHeader: {},
				bannerTransmisionHeader: {},
				bannerTransmisionFooter: {},
				sponsors: [],
			},
			extensions: [
				HardBreak,
				History,
				Blockquote,
				Link,
				Underline,
				Strike,
				Italic,
				ListItem,
				BulletList,
				OrderedList,
				Bold,
				HorizontalRule,
				Paragraph,
			],

		}),
		mounted(){
			this.getData(this.$route.params.id)
		},
		methods: {
			async getData(id){
				let me = this;

				// const {data} = await axios.get(`evento/query?_id=${id}` ,configuration)

				await axios.get(`evento/query?_id=${id}` ,configuration)
					.then(function (response) {
						me.evento._id = response.data._id,
						me.evento.titulo = response.data.titulo,
						me.evento.slug = response.data.slug,
						me.evento.subtitulo = response.data.subtitulo,
						me.evento.informacion = response.data.informacion,
						me.evento.fechaInicio = Helpers.dateFormatUs(response.data.fechaInicio),
						me.evento.fechaFin = Helpers.dateFormatUs(response.data.fechaFin),
						me.evento.gratis = response.data.gratis,
						me.evento.presencial = response.data.presencial,
						me.evento.precios = response.data.precios,
						me.precios = response.data.precios,
						me.evento.afiche = response.data.afiche,
						me.evento.programa = response.data.programa,
						me.evento.librito = response.data.librito,
						me.evento.webinar = response.data.webinar,
						me.evento.meeting = response.data.meeting,
						me.sponsors = response.data.sponsors,
						me.evento.sponsors = response.data.sponsors,
						me.evento.bannerDetalleHeader = response.data.bannerDetalleHeader ?? banner,
						me.evento.bannerInscripcionHeader = response.data.bannerInscripcionHeader ?? banner,
						me.evento.bannerInscripcionEmail = response.data.bannerInscripcionEmail ?? banner,
						me.evento.bannerTransmisionHeader = response.data.bannerTransmisionHeader ?? banner,
						me.evento.bannerTransmisionHeader = response.data.bannerTransmisionHeader ?? banner,
						me.evento.bannerTransmisionFooter = response.data.bannerTransmisionFooter ?? banner
					})
					.catch(function (e) {
						console.log(e.message)
					})
			},
			async updateEvento() {
				this.saving = true
				if ( !this.evento.gratis  && this.evento.precios.length == 0 ) {
						this.$store.commit('SET_ALERT_MESSAGE', {
							show: true,
							color: 'red',
							message: 'Lo sentimos, Evento Pago sin precios! Revise',
							timeout: 4000
						}, {root: true})
						this.saving = false
						return
				}

				try {
					const resp = axios.put('evento/update', {
						_id: this.evento._id,
						titulo: this.evento.titulo,
						slug: this.evento.slug,
						subtitulo: this.evento.subtitulo,
						informacion: this.evento.informacion,
						fechaInicio: this.evento.fechaInicio,
						fechaFin: this.evento.fechaFin,
						gratis: this.evento.gratis,
						presencial: this.evento.presencial,
						precios: this.evento.precios,
						afiche: this.evento.afiche,
						programa: this.evento.programa,
						librito: this.evento.librito,
						webinar: this.evento.webinar,
						meeting: this.evento.meeting,
						bannerDetalleHeader: this.evento.bannerDetalleHeader,
						bannerInscripcionHeader: this.evento.bannerInscripcionHeader,
						bannerInscripcionEmail: this.evento.bannerInscripcionEmail,
						bannerTransmisionHeader: this.evento.bannerTransmisionHeader,
						bannerTransmisionHeader: this.evento.bannerTransmisionHeader,
						bannerTransmisionFooter: this.evento.bannerTransmisionFooter,
						sponsors: this.evento.sponsors
					}, configuration)
					this.$store.commit('SET_ALERT_MESSAGE', {
							show: true,
							color: 'success',
							message: 'Evento actualizado satisfactoriamente',
							timeout: 4000
						}, {root: true})
						this.saving = false
				} catch (e) {
					this.$store.commit('SET_ALERT_MESSAGE', {
						show: true,
						color: 'red',
						message: e.message,
						timeout: 4500
					}, {root: true})
				} finally {
					this.saving = false
				}
			},
			setPrecios(data){
				this.precios = this.evento.precios = data;
			},
			setSponsors(data){
				this.sponsors = this.evento.sponsors = data;
			},
			goEventoList(){
				this.$refs.form.reset()
				this.$refs.form.resetValidation()
				this.$router.push({name: 'EventoList'})
			},
		},
		computed: {
		}
	}
</script>

<style lang="scss" scoped>

</style>